
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {

    apiKey: "AIzaSyC8yTBN4KL811Ts109BlvXFKK9ntCTNx3I",
  
    authDomain: "portfoliowebsite-4b3c1.firebaseapp.com",
  
    projectId: "portfoliowebsite-4b3c1",
  
    storageBucket: "portfoliowebsite-4b3c1.appspot.com",
  
    messagingSenderId: "950938475737",
  
    appId: "1:950938475737:web:a42bc234871de9466472c8",
  
    measurementId: "G-TV38TC6Y5L"
  
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  export const logAnalyticsEvent = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
  };
  
  export { analytics };
  